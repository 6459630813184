import * as React from 'react';
//import YoutubeBackground from 'react-youtube-background'

import VideoPlayer from "react-background-video-player";

/*import ReactPlayer from 'react-player' src={"./assets/drone_brindavan.mp4" } /*
<VideoPlayer
                        className="video"
                        src={"./assets/drone_brindavan.mp4"}
                        autoPlay={true}
                        muted={true}
                    />
                    <YoutubeBackground videoId={'fHk-fYfHA0A'}  className={'video'} >
                    
                </YoutubeBackground>
*/

class Slider extends React.Component{
    
    render(){
        
        return (
            <div className="MainSlider">
                <div className='player-wrapper'>
                <VideoPlayer
                        className="video"
                        src={"./assets/drone_brindavan.mp4"}
                        autoPlay={true}
                        muted={true}
                >
                    <div className='video'>

                    </div>
                </VideoPlayer>
                
                </div>
            </div>
        );
    }
}

export default Slider;