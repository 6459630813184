import React from 'react';
import { Link } from 'react-router-dom';
//import { Navigate } from 'react-router-dom';
import Login from './Login';
import DsDatePicker from './templates/DsDatePicker';
const url = window.location.protocol + '//' + window.location.host;

let respMsg = '';
let data = {};

class Booking extends React.Component {
    constructor(props) {
        super(props);
        window.scroll({
            top: window.scrollY > 0 ? 0 : 1,
            left: 0,
            behavior: 'smooth',
        });

        let people = window.localStorage.getItem('people');
        people = people !== null ? JSON.parse(people) : [];
        this.state = {
            loading: true,
            mobile: '',
            process: false,
            respMsg: '',
            err: '',
            res: '',
            people: people,
            user: null
        }

        data = this.getDatesSlots();
        console.log(this.state.user);
        this.handleLoad = this.handleLoad.bind(this);
    }
   
    componentDidMount() {
       window.addEventListener('load', this.handleLoad);

    }
   
    componentWillUnmount() { 
      window.removeEventListener('load', this.handleLoad)  
    }
   
    handleLoad() {
        //window.alert('loader');
        console.log(this.props.global.user);
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        const { name } = e.target.elements;
        this.props.global.fireUpdateProfile(name.value).then((result) => {
            this.props.global.user.displayName = name.value;
            this.setState({ loading: false });
            console.log(result);
        }).catch(
            (err) => console.log(err)
        );

    }

    handlemobile = async (e) => {
        e.preventDefault()
        const mobile = e.target.value;
        this.setState({
            mobile: mobile
        })
        return mobile;
    }

    getDatesSlots = (dt = false) => {
        data['sdate'] = window.localStorage.getItem('selectedate');
        this.props.global.selectedDate = data.sdate !== undefined && isNaN(data.sdate) ? new Date(data.sdate) : new Date();


        let people = window.localStorage.getItem('bookingData');
        people = people !== null && people !== undefined ? JSON.parse(people) : [];
        data.people = people;
        let json = window.localStorage.getItem('formdata');
        try {
            json = JSON.parse(json);
        } catch (ex) {
            json = {
                date: '',
                slot: ''
            }
        }
        data.slot = json !== null ? json.slot : '';
        return data;

    }

    showExt = (e) => {
        if (e.target.parentElement.parentElement.querySelector('.extended-content') !== null) {
            e.target.parentElement.parentElement.querySelector('.extended-content').classList.add('d-block');
        }

    }

    selectSlot = (e) => {
        let data = {
            date: this.props.global.datePicker(window.localStorage.getItem('selectedate'), 0, 'YM1D'),
            slot: e.target.value
        }
        window.localStorage.setItem('formdata', JSON.stringify(data));
        if (e.target.value !== '') {
            window.location.href = '#hashPerson';
            document.getElementById('addPerson').classList.add('d-block');
        }
    }


    signoff = (e) => {
        let confirm = window.confirm('Are you sure you want to signout?');
        if(confirm){
            this.props.global.fireSignOut().then((res) => {
                this.props.global.user = null;
                this.setState({
                    isLogin: this.props.global.user
                });
    
                window.scroll({
                    top: window.scrollY > 0 ? 0 : 1,
                    left: 0,
                    behavior: 'smooth'
                });
                window.location.reload();
            }).catch((err) => {
                console.log(err);
            });
        }

    }

    addPersons = async (e) => {
        e.preventDefault();

        const { pname, gender, nationality, dno, dob } = e.target.elements;
        let people = this.state.people;
        //bookingData = bookingData === null || bookingData.length < 1 ? '[]' : bookingData
        let alert = 0;
        try {
            let json = window.localStorage.getItem('formdata');

            //bkdata = JSON.parse(bkdata);

            console.log(json);
            json = JSON.parse(json);
            let slot = json['slot'] !== undefined ? JSON.parse(json['slot']) : false;
            console.log(json);
            console.log(slot);
            let peopleData = {
                pname: pname.value,
                gender: gender.value,
                dob: dob.value,
                nationality: nationality.value,
                dno: dno.value
            };
            /* let postData = {
                 slot: json['slot'],
                 date: json.date, people: {
                     pname: pname.value,
                     gender: gender.value,
                     dob: dob.value,
                     nationality: nationality.value,
                     dno: dno.value
                 }
             };*/
            console.log(people, slot);
            people = people.filter((x) => {
                return (x.dno !== dno.value);
            });
            alert = 1;

            if (slot && people.length < slot.available) {
                people.push(peopleData);
                console.log(people);
                window.localStorage.setItem('people', JSON.stringify(people));
                this.setState({
                    people: people
                })
                alert = 3;
            } else {
                window.alert('Max Number of limit per booking is exceeded!');
                alert = -1;
            }
        } catch (ex) {
            alert = 0;
            console.log(ex);
        }
        console.log(alert);
        return alert;




    }

    addPerson = async (e) => {
        await this.addPersons(e);
        data = this.getDatesSlots();
        console.log(data);
        document.querySelector('#addPerson form').reset();
        document.getElementById('addPerson').classList.remove('d-block');
        this.setState({});
    }

    removePerson = (e) => {
        e.preventDefault();

        var id = e.target.getAttribute('data-id');
        let people = this.state.people;
        //bookingData = typeof (bookingData) === 'string' ? JSON.parse(bookingData) : [];

        if (Array.isArray(people) && people.length > 0) {
            let confirm = window.confirm('Are you sure to remove ' + people[id].pname + '?');
            if (confirm) {
                people.splice(id, 1);
                window.localStorage.setItem('people', JSON.stringify(people));
                this.setState({
                    people: people
                })
                data = this.getDatesSlots();
                window.location.href = '/#rb_people';
            }
        }

    }

    validNumber = async (mobile) => {
        
        if (!isNaN(mobile) && mobile.length > 4) {
            const country = '91';
            let pfm = country + mobile;
            
            await this.props.global.getInbox();
            let inbox = this.props.global.smsinbox;
            if (inbox.messages === undefined) {
                inbox.messages = [];
            }
            inbox.messages.push({ number: '919624444199' });
            if (inbox.messages !== undefined && Array.isArray(inbox.messages)) {

                const found = inbox.messages.filter((x) => {
                    //console.log(x['number'].toString(), pfm);
                    return x['number'].toString() === pfm;
                });
                //console.log(found[0].number, pfm);
                return found[0] !== undefined && found[0].number.toString() === pfm ? found[0].number : false;
            }
            
        }
        //return false;
    }

    validateBooking = async (e) => {
        let mobile;
        if(typeof(e) !== 'string'){
            e.preventDefault();
            mobile = e.target.elements['mobile'].value;
            mobile = await this.validNumber(mobile);
        }else{
            mobile = e;
        }
        
        console.log(mobile);
        if(mobile){
            let fdata = window.localStorage.getItem('formdata');
            respMsg = "<div class='alert alert-info'><strong>SMS recieved!</strong><br />Please wait... Your booking is being processed.</div>";
            this.setState({
                respMsg: respMsg,
                process: true
            });
            fdata = fdata !== null ? JSON.parse(fdata) : {};
            let bookingData = {
                slot: fdata.slot,
                date: fdata.date,
                people: this.state.people,
                mobile: mobile
            };


            console.log(bookingData);
            let resp = await this.props.global.processBooking(bookingData);
            //console.log(resp);
            respMsg = resp !== undefined && resp.res === 'success' ? 
                `<div class="alert alert-success"><strong>${resp.msg}</strong><br /><a href="${window.location.protocol}//${window.location.host}/booking/${resp.id}">View Ticket</a></div>` : `<div class='alert alert-danger'>${resp.msg}</div>`;    
            if(resp !== undefined && resp.res === 'success' ){
                window.localStorage.removeItem('formdata');
                window.localStorage.removeItem('people');
                window.localStorage.removeItem('selectedate');
            }
            //window.localStorage.removeItem('bookingData');
            this.setState({
                respMsg: respMsg, mobile: ''
            });
        }else{
            respMsg = "<div class='alert alert-danger'>SMS not recieved. Please check sms is delivered successfully or wait for sometime.</div>";
            this.setState({
                respMsg: respMsg
            })
        }

        
        
        //console.log();
    }

    validateProcess = (e) => {
        respMsg = "<div class='alert alert-info'>Processing...</div>";
        this.setState({
            respMsg: respMsg
        })
        this.validateBooking(e);
    }

    verifyModal = async (e) => {
        console.log(data.slot);
        let slot;
        try {
            slot = JSON.parse(data.slot);
        } catch (ex) {
            slot = data.slot;
            console.log(ex);
        }
        if (slot === '') {
            alert('Please select a slot');
            document.getElementById('slots').focus();
        } else if (Array.isArray(this.state.people) && this.state.people.length < 1) {
            alert('Please add person for the booking');

            document.getElementById('addPerson').classList.add('d-block');
            window.location.href = '/#hashPerson';
        } else if (Array.isArray(this.state.people) && this.state.people.length > slot.available) {
            alert('Maximum allowed people exceeded for the slot ' + slot.slot);
            document.getElementById('slots').focus();
        } else if (this.state.mobile === undefined || (this.state.mobile !== undefined && this.state.mobile.length < 10)) {
            console.log(this.state.mobile);
            alert('Please enter the mobile number you want to use for the booking!');
            document.getElementById('cmobile').focus();
        } else if (Array.isArray(this.state.people) && this.state.people.length > 0 && this.state.people.length <= slot.available) {
            document.getElementById('verifyBook').classList.add('d-block');
            this.setState({
                process:false,
                respMsg: ''
            })
            let validateNo = await this.validNumber(document.getElementById('cmobile').value)
            //console.log(validateNo, document.getElementById('cmobile').value);
            if(validateNo){
                this.validateBooking(validateNo);
            }
            
        }
        console.log(data);
    }

    getprops = async () => {
        this.auth = this.props.global.fAuth;
        setTimeout(() => {
            console.log(this.auth);
            this.props.global.user = this.auth.currentUser;
            console.log(this.auth.currentUser);
            if (this.auth !== undefined) {
                this.setState({ loading: false })
            }
        }, 1000)

    }

    setPassword = async (e) => {
        e.preventDefault();
        const email = this.props.global.user.email;
        console.log(email);
        let confirm = window.prompt('Please enter your email to confirm');
        if (confirm === email) {
            this.props.global.resetPassword(email).then((res) => {
                console.log(res);
                this.setState({
                    err: '',
                    res: 'A link has been sent to your email address to set a password. Sometimes it may be in spam.'
                });
            }).catch((err) => {

                this.setState({
                    err: this.props.global.fireError(err.message),
                    res: ''
                });

            });
        }

    }
    //this.props.global.Verify();

    render() {
        if (this.state.loading === true) {
            this.getprops();
            return (
                <div className="preloader">
                <div className="loader" id="loader-1"></div>
                </div>
                
            );
        } else {
            return (
                <>
                <section id='booking' className={'page-header proj-det bg-img valign'} style={{
                    backgroundImage: `url('${url}/assets/img/background/charanam.jpg')`
                }} >
                    <div className="container">

                        <div className="row">

                            <div className="timeline containerx">

                                <div className="rightbox" id="topbox">
                                    <div className="rb-container">
                                        <div className="logo d-none">
                                            <a href={url}>
                                                <img src={url + "/assets/img/logobig.png"} alt="Sri Sathya Sai Divya Smriti" />
                                            </a>
                                        </div>
                                        <ul className="rb">
                                            {this.props.global.user === null ?
                                                <li id="rb_login" className="rb-item" ng-repeat="itembx">
                                                    <div id="rb_login_e" className='rb-content' onClick={this.showExt}>
                                                        <div className="timestamp">
                                                            Register / Login <i className='fa fa-angle-double-right'></i>
                                                        </div>
                                                        <div className="item-title">Login to do the booking.</div>

                                                    </div>
                                                    <div className='extended-content'>
                                                        <div className="row">
                                                            <div className='col-md-12 text-center'>
                                                                <Login authcheck={this.props.authcheck} global={this.props.global} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                : <li id="rb_login" className="rb-item" ng-repeat="itembx">
                                                    <div id="rb_login_e" className='rb-content' onClick={this.showExt}>
                                                        <h4>Sairam {this.props.global.user !== null && this.props.global.user.displayName !== null ? this.props.global.user.displayName : 'User'}!</h4>
                                                        <div className="timestamp">
                                                            You are logged-in <i className='fa fa-angle-double-right'></i>
                                                        </div>
                                                        <div className="item-title">
                                                            Set Display Name / View Booking History
                                                        </div>

                                                    </div>
                                                    <div className='extended-content'>
                                                        <div className="row">
                                                            <div className='col-md-12 text-center'>
                                                                {
                                                                    this.props.global.user !== null ?
                                                                        <>


                                                                            <form style={{ position: 'relative' }} onSubmit={this.handleSubmit}>
                                                                                <div className="form-group mb-3">
                                                                                    <input type='text' style={{ fontSize: '12px' }} name='name' className='form-control' placeholder='Set / Change Display Name' defaultValue={this.props.global.user.displayName} />
                                                                                    <button style={{ position: 'absolute', top: '1px', right: '0px' }} type="submit" className='btn btn-theme-1'><i className='fa fa-save' title='Save'></i></button>

                                                                                </div>

                                                                                <div className="mt-5"></div>
                                                                                <div className="form-group mb-3">

                                                                                    <pre>{this.props.global.user.email}</pre>
                                                                                    <button onClick={this.setPassword} className='btn btn-sm btn-primary'>Set Password</button>

                                                                                </div>
                                                                                <span className='text-danger'>{this.state.err}</span>
                                                                                <span className='text-success'>{this.state.res}</span>
                                                                            </form>
                                                                            <ul className='list-group'>
                                                                                <li className='list-group-item'><Link to={'/bookings'}>View Booking History</Link></li>
                                                                            </ul><br />
                                                                            <button className='btn btn-dark' onClick={this.signoff}>Sign out</button>
                                                                        </>
                                                                        : <Login authcheck={this.props.authcheck} global={this.props.global} />
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>}

                                            <li id="rb_date" className="rb-item" ng-repeat="itembx">
                                                <div className='rb-content'>

                                                    <>
                                                        <div className="timestamp">
                                                            Choose a Date <i className='fa fa-angle-double-down'></i>
                                                        </div>
                                                        <div className="item-title">
                                                            <div className='form-group'>
                                                                <DsDatePicker global={this.props.global} />
                                                            </div>
                                                        </div>
                                                    </>

                                                </div>
                                                <div className='extended-content'>
                                                    <div className="row">
                                                        <div className='col-md-12 text-center'>
                                                            <Login authcheck={this.props.authcheck} global={this.props.global} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>

                                            <li id="rb_slot" className="rb-item" ng-repeat="itembx">
                                                <div className='rb-content'>
                                                    <div className="timestamp">
                                                        Choose a Slot <i className='fa fa-angle-double-down'></i>
                                                    </div>
                                                    <div className="item-title">

                                                        <div className="form-group">

                                                            {
                                                                Array.isArray(this.props.global.slots) && this.props.global.slots.length > 0 ?
                                                                    <select className="form-control" name="slots" id="slots" defaultValue={data.slot} onChange={this.selectSlot} required>
                                                                        <option value={''}>Select Time Slot</option>
                                                                        {
                                                                            this.props.global.slots.map((x) => {
                                                                                return (
                                                                                    <option key={x.Slot} value={JSON.stringify({ slot: x.Slot, available: x.Available, booked: x.Booked })}>{x.Slot}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                    : <>{data.sdate === null ? <></> : <div className='text-danger'>No Slot Available on {this.props.global.formatDate(data.sdate, 'YMMMD')}<br /><a href='#rb_date'><strong>Change the date</strong></a></div>}</>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div id='hashPerson'></div>
                                                </div>
                                            </li>

                                            <li id="rb_people" className="rb-item" ng-repeat="itembx">
                                                <div className='rb-content' onClick={this.showExt}>
                                                    <div className="timestamp">
                                                        {
                                                            Array.isArray(data.people) && data.people.length > 0 ?
                                                                <>Accompanying People <i className='fa fa-angle-double-down'></i><hr /></> :
                                                                <>Add Accompanying People <i className='fa fa-angle-double-right'></i></>
                                                        }


                                                    </div>
                                                    <div id="addPerson" style={{ maxWidth: '450px' }} className='extended-content'>
                                                        <h4>Add People</h4><hr />
                                                        <form onSubmit={this.addPerson}>
                                                            <div className="row">

                                                                <div className='col-md-12'>
                                                                    <label>Name</label>
                                                                    <input type={'text'}
                                                                        className="form-control" required id='pname' name="pname[]" placeholder="Person's Name" />

                                                                </div>
                                                                <div className='col-md-12'>
                                                                    <label>Date of Birth</label>
                                                                    <input type={'date'}
                                                                        className="form-control" onClick={this.props.global.showPicker} max={this.props.global.datePicker('', -1825)} required id='dob' name="dob[]" placeholder="Date of Birth" />
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <label>Gender</label>
                                                                    <select name="gender[]"
                                                                        className="form-control" id='gender' required >
                                                                        <option value={''}>Select Gender</option>
                                                                        <option value={'M'}>Male</option>
                                                                        <option value={'F'}>Female</option>
                                                                        <option value={'O'}>Other</option>
                                                                    </select>
                                                                    <br />
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <label>Nationality</label>
                                                                    <select name="nationality[]"
                                                                        className="form-control" id="nationality" required >
                                                                        <option value={'IN'}>Indian</option>
                                                                        <option value={'OTHER'}>OTHER</option>
                                                                    </select>
                                                                    <br />
                                                                </div>
                                                                <div className='col-md-12'>
                                                                    <label>Document number</label>
                                                                    <input pattern=".{5,14}" title='Must be a valid Aadhaar / Passport Number' type={'text'}
                                                                        className="form-control" id="dno" required name="dno[]" placeholder="Aadhaar / Passport Number" />
                                                                    <small><strong>Aadhaar / Passport number may be verified during check-in<br />
                                                                        if you are Indian Citizen please provide Aadhar# otherwise valid Passport#</strong></small>

                                                                </div>
                                                                <div className='row'>


                                                                    <div className='col-md-12 text-right' style={{ textAlign: 'right' }}>

                                                                        <button type='submit' className='btn btn-primary'>Add Person</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>

                                                    </div>
                                                    <div className="item-title">

                                                        {
                                                            Array.isArray(this.state.people) && this.state.people.length > 0 ?
                                                                <>
                                                                    <div className='row'>
                                                                        <div className="col-md-12">
                                                                            <span className='alert alert-info' href='#booknow'>Booking for {this.state.people.length <= 1 ? `${this.state.people.length} person` : `${this.state.people.length} persons`} </span>

                                                                        </div>
                                                                        <hr />
                                                                    </div>
                                                                    <div className=''>
                                                                        <div className=''>

                                                                            {this.state.people.map((x, i) => {
                                                                                return (

                                                                                    <>
                                                                                        <div className='row' key={i}>
                                                                                            <div className='col-2'><span className='indexn'>{i + 1}</span></div>
                                                                                            <div className='col-8'>
                                                                                                <div className='row'>
                                                                                                    <div className='col-md-10'><strong>Name (Gender):</strong> {x.pname}<br />({{ '': '', 'M': 'Male', 'F': 'Female', 'O': 'Other' }[x.gender]})</div>
                                                                                                    <div className='col-md-10'><strong>Birthdate (Age):<br /></strong> {this.props.global.formatDate(x.dob, 'D-M-Y')} ({this.props.global.getAge(x.dob)} Years)</div>
                                                                                                    <div className='col-md-12 col-12'><strong>Document#</strong> {x.dno}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='col-2'>
                                                                                                <button className='btn btn-danger btn-sm deletebtn' onClick={this.removePerson} data-id={i}>X</button>
                                                                                            </div>

                                                                                        </div>
                                                                                        <hr />
                                                                                    </>
                                                                                );
                                                                            })}
                                                                        </div>

                                                                    </div>
                                                                    <button className='btn btn-primary' onClick={() => {
                                                                        window.location.href = '/#hashPerson';
                                                                    }}>Add More People</button>
                                                                </> :
                                                                <>Click / Touch to add accompanying people data</>
                                                        }
                                                    </div>

                                                </div>
                                            </li>

                                            {
                                                this.props.global.user !== null && typeof (data.slot) === 'string'
                                                    ?
                                                    <>
                                                        <li className="rb-item" ng-repeat="itembx" >
                                                            <div className='rb-content'>
                                                                <div className="form-group">
                                                                    Your Mobile Number
                                                                    <input autoComplete='off' type='text' id='cmobile' className="form-control" placeholder="Your 10 digit mobile number" onChange={this.handlemobile} onKeyDown={(e) => {
                                                                        if (e.key === "Enter") {
                                                                            document.querySelector('.timestamp').click();
                                                                        }
                                                                    }} required minLength={10} maxLength={10} /><small>Normal SMS charges may be applicable by your service provider.</small>
                                                                </div>
                                                                <div className="timestamp" onClick={this.verifyModal}>
                                                                    Enter Mobile Number &amp; Confirm Booking <i className='fa fa-flag'></i>
                                                                </div>
                                                                <div className="item-title" onClick={this.verifyModal}>Click / Touch to confirm your booking.</div>
                                                                <hr />
                                                                <div className='text-right'><button className='btn btn-primary' onClick={this.verifyModal}>Validate Mobile</button></div>
                                                                <div id="verifyBook" className='extended-content'>
                                                                    <div className='veriFy'>
                                                                        <form autoComplete="off" onSubmit={this.validateProcess} >
                                                                            <div className='verification text-center'>

                                                                                To confirm the booking, please send sms from<br />
                                                                                <input autoComplete='off' type='text' id='mobile' className="form-control text-center" placeholder="Your 10 digit mobile number" onChange={this.handlemobile} value={this.state.mobile} required minLength={10} maxLength={10} />
                                                                                <pre className='copier' onClick={() => {
                                                                                    navigator.clipboard.writeText(this.props.global.env.textlocal.keyword);
                                                                                    alert('Keyword Copied')
                                                                                }}><code >{this.props.global.env.textlocal.keyword}</code></pre>
                                                                                to<br />
                                                                                <strong>{this.props.global.env.textlocal.number}</strong><br />
                                                                                Click on the box to copy keyword
                                                                                <div className='d-md-block d-none'>

                                                                                    <img alt='QRCODE' className='img_responsive' style={{ width: '200px' }} src='./assets/qr-code.png' /><br />
                                                                                    Scan QR CODE
                                                                                </div>
                                                                                or<br />
                                                                                <a href={`sms:${this.props.global.env.textlocal.number};?body=${this.props.global.env.textlocal.keyword}`} className='btn btn-primary'>Send using this device</a>
                                                                                <hr />
                                                                                <div className="row">
                                                                                    <div className='col-md-12'>

                                                                                        <div id="respMsg" dangerouslySetInnerHTML={{ __html: this.state.respMsg }}></div>
                                                                                        {
                                                                                            this.state.process === true ? 
                                                                                            <div className='row'>

                                                                                                
                                                                                                <div className='col-12 text-right'>
                                                                                                    <button type='button' className='btn btn-dark' onClick={(e) => {
                                                                                                        this.setState({
                                                                                                            process: false
                                                                                                        });
                                                                                                        document.getElementById('verifyBook').classList.remove('d-block');
                                                                                                        
                                                                                                    }}>CANCEL</button>
                                                                                                </div>
                                                                                                <div className='col-12'></div>
                                                                                            </div>
                                                                                            :
                                                                                            <div className='row'>

                                                                                                <div className='col-6'>
                                                                                                    <button type='submit' className='btn btn-primary' onClick={() => {
                                                                                                        respMsg = "<div class='alert alert-info'>Processing...</div>";
                                                                                                        this.setState({
                                                                                                            respMsg: respMsg
                                                                                                        })
                                                                                                    }}>Validate</button>
                                                                                                </div>
                                                                                                <div className='col-6'>
                                                                                                    <button type='button' className='btn btn-dark' onClick={(e) => {
                                                                                                        document.getElementById('verifyBook').classList.remove('d-block');
                                                                                                    }}>CANCEL</button>
                                                                                                </div>
                                                                                                <div className='col-12'></div>
                                                                                            </div>
                                                                                        }
                                                                                        


                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </> : <>
                                                        <li className="rb-item" ng-repeat="itembx" onClick={() => {
                                                            alert('Please login to continue booking!');
                                                            window.location.href = '/#topbox';
                                                            document.getElementById('rb_login_e').click();
                                                        }}>
                                                            <div className='rb-content'>
                                                                <div className="timestamp">
                                                                    Login to Book
                                                                </div>
                                                                <div className="item-title">Login is required to make a booking. There is no cost for booking a ticket.</div>
                                                            </div>
                                                        </li>
                                                    </>
                                            }

                                        </ul>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </section>
                </>
                
            );
        }

    }
}

export default Booking;