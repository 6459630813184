import { initializeApp } from "firebase/app";
import { getAuth, getRedirectResult } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import env from "./Vars";

class initApp {
  constructor() {
    this.auth = this.init();
    this.env = env;

  }

  init = async () => {
    this.host = env.api.host; //without tailing slash /
    this.hosturl = this.__();
    this.adminurl = env.adminurl;
    this.headers = env.headers;
    this.fApp = initializeApp(env.firebase.config);
    this.fAuth = getAuth(this.fApp);
    this.db = getFirestore(this.fApp);
    this.user = this.fAuth.currentUser;
    //console.log(window.location.origin);
    this.fAC = env.firebase.accessCode;
    return await getRedirectResult(this.fAuth);
  }
  Auth = () => {
    this.fAuth = getAuth(this.fApp);
    this.user = this.fAuth.currentUser;
    return this.fAuth;
  }

  prefix = (str) => {
    return this.env.database.prefix + str;
  }

  showPicker = (e) => {
    e.preventDefault();
    const el = e.target;
    if (el.getAttribute("type") === "date") {
      el.showPicker();
    }
  }
  getAge = (date) => {
    date = date === undefined || date === '' ? new Date() : date;
    date = typeof (date) === 'string' ? new Date(date) : date;

    return Math.floor((new Date() - date.getTime()) / 3.15576e+10);
  }



  firstDate = (dates) => {
    let dt = dates.length > 0 ? new Date(
      Math.min(
        ...dates.map(element => {
          // console.log(element);
          return new Date(element);
        }),
      ),
    ) : new Date();
    dt = (dt.getTime() <= (new Date()).getTime()) ? (new Date()) : dt;
    //console.log('min',dt);
    //console.log(dt.getTime() <= (new Date()).getTime());
    return dt;
  }

  lastDate = (dates) => {
    let dt = dates.length > 0 ? new Date(
      Math.max(
        ...dates.map(element => {
          // console.log(element);
          return new Date(element);
        }),
      ),
    ) : new Date();
    // console.log('max',dt);
    return dt;
  }

  formatDate = (dt, format = '', sep = '-') => {

    if (dt === undefined) {
      return '';
    }
    dt = typeof (dt) === 'string' ? new Date(dt) : dt;
    let months = [
      '',
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ]
    let date;

    switch (format) {
      case 'YMD':
        date = [dt.getFullYear(), dt.getMonth(), dt.getDate()];

        date[1] = date[1] < 10 ? '0' + date[1] : date[1];
        date[2] = date[2] < 10 ? '0' + date[2] : date[2];
        date = date.join(sep);
        break;
      case 'ymd':
        date = [dt.getFullYear(), dt.getMonth(), dt.getDate()];
        date = date.join(sep);
        break;
      case 'YMMMD':
        date = [dt.getDate(), months[dt.getMonth()], dt.getFullYear()].join(' ');
        break;
      case 'D-M-Y':
        date = [dt.getDate(), months[dt.getMonth() + 1].substring(0, 3), dt.getFullYear()].join('-');
        break;
      default:
        date = [dt.getDate(), months[dt.getMonth() + 1], dt.getFullYear()].join(' ');
        break;
    }
    return date;
  }

  dragElement = (elmnt) => {
    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    if (document.querySelector(elmnt.id + " mover")) {
      /* if present, the header is where you move the DIV from:*/
      document.querySelector(elmnt.id + " .mover").onmousedown = dragMouseDown;
    } else {
      /* otherwise, move the DIV from anywhere inside the DIV:*/
      elmnt.onmousedown = dragMouseDown;
      elmnt.ontouchstart = dragMouseDown;
    }

    function dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    }

    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      // set the element's new position:
      elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
      elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
    }

    function closeDragElement() {
      /* stop moving when mouse button is released:*/
      document.onmouseup = null;
      document.onmousemove = null;
    }
  }

  datePicker = (date = '', days = 0, format = 'YMD', sep = '-') => {
    days = days * (24 * 60) * 60 * 1000;
    date = date === undefined || date === '' ? new Date() : date;
    date = typeof (date) === 'string' ? new Date(date) : date;

    let time = date.getTime();
    time += days;
    date = new Date(time);

    switch (format) {
      case 'YMD':
        date = [date.getFullYear(), date.getMonth(), date.getDate()];
        date[1] = date[1] < 10 ? '0' + date[1] : date[1];
        date[2] = date[2] < 10 ? '0' + date[2] : date[2];
        date = date.join(sep);
        break;
      case 'YM1D':
        date = [date.getFullYear(), (parseInt(date.getMonth()) + 1), date.getDate()];
        date[1] = date[1] < 10 ? '0' + date[1] : date[1];
        date[2] = date[2] < 10 ? '0' + date[2] : date[2];
        date = date.join(sep);
        break;
      case 'ymd':
        date = [date.getFullYear(), date.getMonth(), date.getDate()];
        date = date.join(sep);
        break;
      default:
        date = [date.getFullYear(), date.getMonth(), date.getDate()];
        break;
    }
    return date;
  }

  __ = (str) => {
    return `${window.location.protocol}//${window.location.host}/${str}`;
  }

  url = (str) => {
    try {
      const regex = new RegExp("^(http:\\/\\/|https:\\/\\/|\\/\\/)(.*)$", 'si');
      const ats = regex.exec(str);
      return {
        full: ats[0],
        prototype: ats[1],
        link: ats[2]
      };
    } catch (ex) {
      console.log(ex);
      return false;
    }
  }

  scripts = (selector = false, data = []) => {
    let scripts = [];
    selector = !selector ? document.body : document.querySelector(selector);
    console.log(selector);
    if (Array.isArray(data)) {
      data.forEach((x) => {
        let sc = document.createElement('script');
        sc.src = this.url(x.src) ? x.src : this.__(x.src);
        if (x.attr !== undefined) {
          Object.entries(x.attr).forEach((obj) => {
            if (obj[0] !== 'src') {
              sc.setAttribute(obj[0], obj[1]);
            }
          });
        }
        scripts.push(sc);
      })
    } else {
      let sc = document.createElement('script');
      console.log('data', this.url(data.src));
      sc.src = this.url(data.src) ? data.src : this.__(data.src);
      if (data.attr !== undefined) {
        Object.entries(data.attr).forEach((obj) => {
          if (obj[0] !== 'src') {
            sc.setAttribute(obj[0], obj[1]);
          }
        });
      }
      scripts.push(sc);
    }

    if (selector !== null) {
      selector.innerHTML = '';
      scripts.forEach((x) => {

        selector.append(x);
        console.log(x);
      })
    }
    //document.writeln(scripts.outerHTML());
  }

  links = (selector, data = []) => {
    let scripts = [];
    selector = !selector ? document.body : document.querySelector(selector);
    if (Array.isArray(data)) {
      data.forEach((x) => {
        let sc = document.createElement('link');
        sc.href = this.url(x.href) ? x.href : this.__(x.href);
        if (x.attr !== undefined) {
          Object.entries(x.attr).forEach((obj) => {
            if (obj[0] !== 'href') {
              sc.setAttribute(obj[0], obj[1]);
            }
          });
        }
        scripts.push(sc);
      })
    } else {
      let sc = document.createElement('link');
      sc.src = this.url(data.href) ? data.href : this.__(data.href);
      if (data.attr !== undefined) {
        Object.entries(data.attr).forEach((obj) => {
          if (obj[0] !== 'href') {
            sc.setAttribute(obj[0], obj[1]);
          }
        });
      }
      scripts.push(sc);
    }
    if (selector !== null) {
      selector.innerHTML = '';
      scripts.forEach((x) => {

        selector.append(x);
        console.log(x);
      })
    }
  }

  replace = (str, find, replace = '') => {
    var regx = new RegExp(find, 'g');
    str = str.replace(regx, replace);
    //console.log(str);
    return str;
  }

}

export default initApp;