import React from 'react';
import { FaUser, FaEye } from 'react-icons/fa';


class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pwd: true,
      loginerr: '',
      loginres: '',
      submitted: false,
      pwdLogin: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
  }

  checkPwd = (e) => {
    console.log(e.target);
    this.setState({
      pwdLogin: (this.state.pwdLogin) ? false : true
    });
  }

  togglePassword = () => {
    this.setState({
      pwd: (this.state.pwd) ? false : true
    });
  }

  refreshPage() {
    window.location.reload(false);
  }

  loginWith(method, e) {
    this.setState({
      loginerr: '',
      loginres: 'Checking...',
      submitted: true
    });
    const { email, password } = e.target.elements;
    switch (method) {
      case 'Password':
        this.props.global.firebaseLoginPassword(email.value, password.value).then((res) => {
          console.log(res);
          this.setState({
            loginerr: '',
            loginres: 'Validating Login...',
            submitted: true
          });
          setTimeout(() => {
            this.refreshPage();
          }, 1000);

        }).catch((e) => {
          //const errorCode = e.code;
          //const errorMessage = e.message;
          this.setState({
            loginerr: this.props.global.fireError(e.message),
            loginres: ''
          });

          console.log(e);
          // ...
        });
        break;
      default:
        this.props.global.firePasswordLess(email.value).then((res) => {

          console.log(res);
          window.localStorage.setItem('emailForSignIn', email.value);
          this.setState({
            loginerr: '',
            loginres: 'Please check your mail\'s inbox/spam folder',
            submitted: true
          });

          // ...
        })
          .catch((e) => {
            //const errorCode = e.code;
            //const errorMessage = e.message;
            this.setState({
              loginerr: this.props.global.fireError(e.message),
              loginres: ''
            });

            console.log(e);
            // ...
          });
        break;
    }
    setTimeout(() => {
      this.setState({ submitted: false });
    }, 3000)
  }

  handleSubmit = async (e) => {
    e.preventDefault()
    this.loginWith(this.state.pwdLogin ? 'Password' : '', e);

  }
  render() {

    /*
    <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span onClick={this.togglePassword} className="input-group-text" style={{padding:".63rem .75rem", background:"none",borderRight:"0px"}}><FaEye /></span>
                    </div>
                    <input type={this.state.pwd ? 'password' : 'text'} style={{borderLeft:"0px", outline: "none"}} className="form-control" placeholder="Enter Password" name="password" minLength='6' />
                </div>
    */
    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <div className='row'>

            <div className='col-md-12'>
              <div className="form-group">
                <div className="input-group mb-3">
                  <div className="input-group-prepend" >
                    <span className="input-group-text" style={{ padding: ".63rem .75rem", background: "none", borderRight: "0px" }}><FaUser /></span>
                  </div>
                  <input name="email" style={{ borderLeft: "0px", outline: "none" }} className="form-control" placeholder="Enter Email Address" type="email" required />
                  <br />

                </div>
                <div className='m-3'></div>
              </div>
              <div className='form-group' style={{ textAlign: 'left' }}>
                <div className="form-check form-switch">
                  <input className="form-check-input" onChange={this.checkPwd} type="checkbox" id="flexSwitchPassword" />
                  <label className="form-check-label" htmlFor="flexSwitchPassword">Login with password</label>
                </div>
                <div className='m-3'></div>
              </div>
              {this.state.pwdLogin ?
                (
                  <div className="form-group">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span onClick={this.togglePassword} className="input-group-text" style={{ padding: ".63rem .75rem", background: "none", borderRight: "0px" }}><FaEye /></span>
                      </div>
                      <input type={this.state.pwd ? 'password' : 'text'} style={{ borderLeft: "0px", outline: "none" }} className="form-control" placeholder="Enter Password" name="password" minLength='6' required />
                    </div>
                    <div className='m-3'></div>
                  </div>
                )
                : (<></>)}

              <div className="form-group">

                <div className='m-3'></div>
                <div className={this.state.loginerr === '' ? '' : 'alert alert-danger'} >{this.state.loginerr}</div>
                <div className={this.state.loginres === '' ? '' : 'alert alert-success'} >{this.state.loginres}</div>
                <div className='m-3'></div>
              </div>
              <div className="form-group"><button disabled={this.state.submitted} type="submit" className='btn btn-theme-1'>LOGIN / REGISTER</button></div>
            </div>

          </div>


        </form>
      </>
    );
  }
}


export default Login;