import * as React from 'react';
import { Helmet } from "react-helmet";
class Header extends React.Component{

  constructor (props) {  
    super(props);
    this.__ = this.props.global.__;
  }


  render()
  {
    return (
      
        <>
        <Helmet>
          
          <title>{this.props.global.meta.title}</title>
          <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
          <meta name="description" content={this.props.global.meta.description} />
          <meta name="keywords" content={this.props.global.meta.keywords} />
          
          <link rel="shortcut icon" href={this.__("assets/img/sai/favicon.ico")} />
          <link
            href="https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&amp;display=swap"
            rel="stylesheet"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@100;200;300;400;500;600;700;800;900&amp;display=swap"
            rel="stylesheet"
          />
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.2/css/all.min.css"
          />
          <link rel="stylesheet" href={this.__("/assets/css/plugins.css")} />
          <link href={this.__("assets/css/style.css")} rel="stylesheet" type="text/css" media="all" />
          
          <link rel="shortcut icon" href={this.__("assets/img/sai/favicon.ico")} />
          
          <script src={this.__("assets/js/plugins.js")}></script>
          <script src={this.__("assets/js/scripts.js")}></script>
          
          <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p" crossOrigin="anonymous"></script>

        </Helmet>
        </>
    );
  }
}

export default Header;
