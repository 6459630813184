import React from "react";
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import './style.css';
import Global from './Global';
import Layout from './pages/Layout';
import Booking from './pages/Booking';
import NotFound from './pages/NotFound';
import Bookings from "./pages/Bookings";
import BookingList from "./pages/BookingList";
//import Home from "./pages/Home";

class App extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      sticky: false,
      doLogin: false,
      loading: true,
      location: '',
      loggedin: false,
      availableDates: []
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.global = new Global();
    this.global.url = window.location.protocol + '//' + window.location.host;
    this.bookingData = window.localStorage.getItem('bookingData');
    this.getprops();
    this.handleLoad();
    // this.global.firebaseAuth = this.authcheck();
    //console.log(this.global.user);

    if (this.global.query.mode !== undefined && this.global.query.mode === "signIn") {
      this.global.fireVerifyPasswordLess(this.global.user)
        .then((result) => {
          // Clear email from storage.


          window.localStorage.removeItem('emailForSignIn');
          if (result !== undefined && result.user !== undefined && result.user.uid !== null) {
            this.global.fAuth = this.global.Auth();
            window.scroll({
              top: window.scrollY > 0 ? 0 : 1,
              left: 0,
              behavior: 'smooth'
            });
            window.location.href = window.location.protocol + '//' + window.location.host + window.location.pathname;
            //window.location.reload();
          }

        })
        .catch((error) => {
          console.log(error);
          //console.log(errorMessage);
          alert(this.global.fireError(error.message));
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }

    // var query = this.global._queryString();
    //console.log();
    //this.loginClose = this.loginClose.bind(this);
  }

  getprops = async () => {
    this.auth = this.global.fAuth;
    setTimeout(() => {
      console.log(this.auth);
      this.global.user = this.auth.currentUser;
      console.log(this.auth.currentUser);
      if (this.auth !== undefined) {
        this.setState({ loading: false })
      }
    }, 1000)

  }

  componentDidMount() {
    //this.handleLoad();
    window.addEventListener('load', this.handleLoad, true);
    window.addEventListener('scroll', this.handleScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.handleLoad);
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleLoad = async () => {
    const data = await this.global.getAvailableDates();
    this.global.availableDates = data;
    return data;
  }

  handleScroll = () => {
    this.setState({
      sticky: (window.scrollY >= window.innerHeight)
    });
  };

  loginOpen = () => {

    if (this.global.user === null || this.global.fAuth === undefined) {
      this.setState({
        doLogin: true
      });
      //return false;
      console.log('open', this.state);
    } else {
      console.log('close', this.state);
      // return true;
    }
  }

  loginClose = () => {
    this.setState({
      doLogin: false
    });
    console.log('close', this.state);
  }


  render() {

    if (this.state.loading === true) {
      this.getprops();
      return (
        <div className="preloader">
          <div class="loader" id="loader-1"></div>
        </div>
      );
    } else {

      return (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout sticky={this.state.sticky} location={useLocation} authcheck={this.authcheck} loginClose={this.loginClose} loginOpen={this.loginOpen} doLogin={this.state.doLogin} global={this.global} />}>
              <Route path="/booking/:id" element={<Bookings title="My Booking" auth={this.authcheck} global={this.global} />} />
              <Route index element={<Booking sticky={this.state.sticky} authcheck={this.authcheck} loginOpen={this.loginOpen} doLogin={this.state.doLogin} global={this.global} />} />

              <Route path="/bookings" element={<BookingList title="My Bookings" auth={this.authcheck} global={this.global} />} />

              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>

      );
    }

  }

}

export default (App);