import * as React from 'react';
//import footerContent from './Footer/data';

class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            footer: ''
        }
        this.__ = this.props.global.__;

    }

    componentDidMount() {
        this.props.global.scripts('#fLoader', [
            //  {src:'assets/js/plugins.js', attr: { } },
            //  {src:'assets/js/scripts.js', attr: { } }
        ])
    }


    render() {
        return (<>
            <div id='fLoader'></div>

        </>)
    }
}
/*
const Footer = () => ( 
    <div id="footer">
        <footer>
            <div className="footer-content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            {footerContent.section1.content()}
                        </div>
                        <div className="col-md-4">
                            {footerContent.section2.content()}
                        </div>
                        <div className="col-md-4">
                            {footerContent.section3.content()}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
); */

export default Footer;