import * as React from 'react';
class NotFound extends React.Component {
  componentDidMount() {
    this.setState({ title: 'Error 404! Page Not Found :(' });
  }

  render() {
    return (
      <div>
        <article>The Page you are looking for could not be found..</article>
      </div>
    );
  }
}

export default NotFound;
