import * as React from 'react';
import { Outlet } from 'react-router-dom';
import HContainer from './Layouts/HContainer';
import Header from './Layouts/Header';
import Footer from './Layouts/Footer';
import { Helmet } from 'react-helmet';

class Layout extends React.Component {
  constructor (props) {  
    super(props);
    console.log(this.props)
  }

  render() {
    
    return (
      <>
        <Helmet>
          <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=7edadffa-45d7-4172-bce4-520329e77e45"> </script>
        </Helmet>
        <Header global={this.props.global} />
        <HContainer sticky={this.props.sticky} loginOpen={this.props.loginOpen} authcheck={this.props.authcheck} global={this.props.global} loginClose={this.props.loginClose} doLogin={this.props.doLogin} />
        <Outlet />
        <Footer  global={this.props.global} />
                
      </>
    );
  }
}
export default Layout;
